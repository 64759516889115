<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="FormMSG(1, 'Accommodation Added')"
		ok-only
		ok-variant="success"
		size="md"
		centered
		:ok-title="FormMSG(2, 'Ok')"
		no-close-on-esc
		no-close-on-backdrop
		@hidden="emitEventClose"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="alert alert-success d-flex align-items-center">
			<div>
				<component :is="getLucideIcon('CheckCircle')" :size="26" />
			</div>
			<div class="ml-3" style="font-size: 1.1rem">
				{{
					FormMSG(
						3,
						'Your accommodation was added successfully, but not published yet. You can publish it now (or later) by clicking the "Send details" button.'
					)
				}}
			</div>
		</div>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'AccomodationSuccessModal',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	methods: {
		emitEventClose() {
			this.$emit('accomodation-success-modal:closed');
		}
	}
};
</script>
