<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="title"
		size="xl"
		centered
		cancel-variant="light"
		@show="resetModal"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="container-layout">
			<accomodation-update-form
				ref="form"
				:action="action"
				:enable-btn-report="false"
				:display-add-btn="false"
				:item="item"
				@accomodation-update-form:waiting="onAccomodationLoaded"
				@accomodation-update-form:close-modal="onAccomodationForceClosed"
				@accomodation-update-form:refresh="onAccomodationRefreshed"
			/>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" @click="emitEventClose" block>
					{{ FormMSG(1, 'Close') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="watingSubmitForm" @click="dispatchEvent" block v-if="btnCloseOnly">
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div class="pl-2" style="margin-top: 1px">{{ FormMSG(2, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import AccomodationUpdateForm from '@/components/Accomodation/UpdateForm';
import _ from 'lodash';

export default {
	name: 'AccomodationUpdateModal',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		item: {
			type: Object,
			required: true,
			default: null
		},
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		action: {
			type: String,
			required: true,
			default: ''
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		},
		title() {
			return this.action === 'UPDATE' ? this.FormMSG(3, 'Update Booking') : this.FormMSG(4, 'Booking Details');
		}
	},
	components: {
		AccomodationUpdateForm
	},
	data() {
		return {
			watingSubmitForm: false,
			btnCloseOnly: false
		};
	},
	methods: {
		emitEventClose() {
			this.$emit('accomodation-update-form-modal:closed');
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.$refs['form'].$refs['submit'].click();
		},
		onAccomodationLoaded(payload) {
			this.watingSubmitForm = payload;
		},
		onAccomodationForceClosed(payload) {
			if (!_.isNil(payload)) {
				this.$emit('accomodation-update-form-modal:closed', payload);
			}
		},
		resetModal() {
			this.btnCloseOnly = this.action === 'UPDATE';
		},
		onAccomodationRefreshed() {
			this.$emit('accomodation-update-form-modal:reload');
		}
	}
};
</script>
